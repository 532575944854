<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title">Quote Setting</h2>
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="button"
              class="is-command"
              icon-left="plus"
              @click="openModalCreate()"
            >
              New
            </b-button>
          </div>
        </div>
      </template>
      <template #page-content>
        <div id="divisions-table">
          <b-table
            :data="quotesList"
            :loading="isLoading"
            ref:table
            hoverable
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            height="760px"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="5%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>
              <b-table-column
                field="quote"
                label="Quote"
                v-slot="props"
                sortable
                width="67%"
              >
                <span class="is-capitalize">{{ props.row.quote }}</span>
              </b-table-column>
              <b-table-column label="Action" v-slot="props" width="8%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalCreate(props.row)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="delete"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="deleteQuotes(props.row.id)"
                ></b-icon>
              </b-table-column>
            </template>
            <template #empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are you sure?"
          v-if="isModalCreateOpen"
          @hide="closeModalCreate"
          :is-modal="true"
          class="modal-allowance-type"
        >
          <template #page-content>
            <h3 class="modal-title has-text-weight-black">
              {{
                isEditing
                  ? 'Edit Allowance Category'
                  : 'Create New Allowance Category'
              }}
              <span class="office-pointer-click is-pulled-right">
                <b-icon
                  icon="close"
                  size="is-small"
                  @click.native="closeModalCreate"
                ></b-icon>
              </span>
            </h3>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submit)"
                class="form-timeoff-type"
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="Quote"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Quote"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-input
                          v-model="form.quote"
                          type="textarea"
                          placeholder="Insert quote here"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-12">
                    <b-button
                      native-type="submit"
                      type="is-primary"
                      expanded
                      :loading="isSubmitting"
                    >
                      {{ isEditing ? 'Submit' : 'Add New' }}
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'

export default {
  data() {
    return {
      isModalCreateOpen: false,
      isEditing: false,
      quotes: [],
      isLoading: false,
      stickyHeaders: true,
      form: {
        id: null,
        quote: null,
      },
      isSubmitting: false,
      isLoadMore: false,

      perPage: 20,
      page: 0,
      lastPage: 0,
      defaultSortOrder: 'desc',
      sortField: 'id',
      sortOrder: 'desc',
    }
  },
  computed: {
    ...mapGetters({
      quotesList: 'quotes/getQuotesList',
    }),
  },
  async mounted() {
    this.setQuotesList([])
    await this.loadQuotesList()
  },
  methods: {
    ...mapActions({
      actionLoadQuotesList: 'quotes/fetchQuotesList',
      saveQuote: 'quotes/saveQuote',
      updateQuote: 'quotes/editQuote',
      deleteQuote: 'quotes/deleteQuote',
    }),
    ...mapMutations({
      setQuotesList: 'quotes/setQuotesList',
    }),
    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.form = {
          id: row.id,
          quote: row.quote,
        }
      }
      this.isModalCreateOpen = true
    },

    closeModalCreate() {
      this.resetForm()
      this.isModalCreateOpen = false
      this.isEditing = false
    },

    resetForm() {
      this.form = {
        id: null,
        quote: null,
      }
    },

    async loadQuotesList() {
      this.isLoading = true

      try {
        const response = await this.actionLoadQuotesList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })
        this.quotes = response.data.data
        this.total = response.data.total
        this.lastPage = response.data.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLoading = false
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setQuotesList([])
      await this.loadQuotesList()
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.actionLoadQuotesList()
        this.isLoadMore = false
      }
    },

    async deleteQuotes(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action`,
      })
      try {
        if (res && res.isConfirmed) {
          await this.deleteQuote(id)

          this.$swal({
            icon: 'success',
            titleText: 'Data is successfully deleted!',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.resetForm()
        }
      } catch (e) {
        this.$swal({
          icon: 'error',
          titleText: 'Failed. Please try again!',
          text: e,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
    },

    async submit() {
      this.isSubmitting = true
      let form = new FormData()

      form.append('quote', this.form.quote)

      if (!this.isEditing) {
        try {
          await this.saveQuote(form)
          showToast('Quote Added!', 'is-success', 'is-bottom')
        } catch (e) {
          showToast(e.message, 'is-danger', 'is-bottom')
        }
      } else {
        try {
          const data = { id: this.form.id, form: this.form }
          await this.updateQuote(data)
          showToast('Quote Edited!', 'is-success', 'is-bottom')
        } catch (e) {
          showToast(e.message, 'is-danger', 'is-bottom')
        }
      }

      this.isSubmitting = false
      this.closeModalCreate()
    },
  },
}
</script>
